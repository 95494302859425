import { JSONSchemaType } from 'ajv';
import { ProductEnum } from 'src/types/ProductEnum';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';

interface Address {
    line1: string;
    line2?: string;
    line3?: string;
}

interface Property {
    id: number;
    town: string;
    active: number;
    country: string;
    postcode: string;
    address: Address;
}

interface GoCardlessPaymentDetails {
    mandateId?: string | null;
    customerId?: string | null;
    subscriptionId?: string | null;
}

export interface Contract {
    id: number;
    status: number;
    packageId: number;
    startDate: string;
    endDate: string;
    property: Property;
    billingType: number;
    contribution: number;
    pricebookId: number;
    paymentAmount: number;
    contractCustomerFacingId: string;
    paymentDetails: GoCardlessPaymentDetails;
    accountId: number;
    skAccountId: string;
    isRenewal?: boolean | null;
    includedProducts: ProductEnum[];
    paymentsUpToDate: boolean;
    canRaiseClaim: boolean | null;
    isOilPackage: boolean;
    packageDetails: {
        onlyLossOfService: boolean | null;
        heatPumpService: boolean | null;
        tapsService: boolean | null;
        showersService: boolean | null;
        requiresInsurerApproval: boolean | null;
        claimCostLimitGbp: number | null;
        annualClaimCostLimitGbp: number | null;
    };
    annualClaimCost: number | null;
}

export interface GetContractSuccessResponse {
    data: Contract[];
}

export type GetContractResponse = GetContractSuccessResponse | PandoraErrorResponse;

export const addressSchema: JSONSchemaType<Address> = {
    type: 'object',
    properties: {
        line1: {
            type: 'string',
        },
        line2: {
            type: 'string',
            nullable: true,
        },
        line3: {
            type: 'string',
            nullable: true,
        },
    },
    required: ['line1'],
};

export const propertySchema: JSONSchemaType<Property> = {
    type: 'object',
    properties: {
        id: { type: 'integer' },
        active: { type: 'number' },
        address: addressSchema,
        country: { type: 'string' },
        postcode: { type: 'string' },
        town: { type: 'string' },
    },
    required: ['active', 'address', 'country', 'id', 'postcode', 'town'],
};

export const goCardlessPaymentDetailsSchema: JSONSchemaType<GoCardlessPaymentDetails> = {
    type: 'object',
    properties: {
        mandateId: { type: 'string', nullable: true },
        customerId: { type: 'string', nullable: true },
        subscriptionId: { type: 'string', nullable: true },
    },
    required: [],
};

export const contractSchema: JSONSchemaType<Contract> = {
    type: 'object',
    properties: {
        id: { type: 'number' },
        status: { type: 'number' },
        accountId: { type: 'integer' },
        packageId: { type: 'number' },
        startDate: { type: 'string', format: 'date-time' },
        endDate: { type: 'string', format: 'date-time' },
        property: propertySchema,
        billingType: { type: 'number' },
        contribution: { type: 'number' },
        pricebookId: { type: 'number' },
        paymentAmount: { type: 'number' },
        contractCustomerFacingId: { type: 'string' },
        paymentDetails: goCardlessPaymentDetailsSchema,
        isRenewal: { type: 'boolean', nullable: true },
        skAccountId: { type: 'string' },
        includedProducts: {
            type: 'array',
            items: {
                type: 'string',
                enum: [
                    'boiler',
                    'heating',
                    'electric',
                    'plumbing',
                    'drainage',
                    'windows',
                    'locks',
                    'pests',
                    'doors',
                    'taps',
                    'showers',
                    'appliances',
                ],
            },
        },
        paymentsUpToDate: { type: 'boolean' },
        canRaiseClaim: { type: 'boolean', nullable: true },
        isOilPackage: { type: 'boolean' },
        packageDetails: {
            type: 'object',
            properties: {
                onlyLossOfService: { type: 'boolean', nullable: true },
                heatPumpService: { type: 'boolean', nullable: true },
                tapsService: { type: 'boolean', nullable: true },
                showersService: { type: 'boolean', nullable: true },
                requiresInsurerApproval: { type: 'boolean', nullable: true },
                claimCostLimitGbp: { type: 'number', nullable: true },
                annualClaimCostLimitGbp: { type: 'number', nullable: true },
            },
            required: ['onlyLossOfService'],
        },
        annualClaimCost: { type: 'number', nullable: true },
    },
    required: [
        'id',
        'status',
        'packageId',
        'startDate',
        'endDate',
        'property',
        'billingType',
        'contribution',
        'pricebookId',
        'paymentAmount',
        'contractCustomerFacingId',
        'paymentDetails',
        'accountId',
        'paymentsUpToDate',
        'skAccountId',
        'includedProducts',
        'isOilPackage',
        'packageDetails',
    ],
};

export const getContractSchema: JSONSchemaType<GetContractSuccessResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: contractSchema,
        },
    },
    required: ['data'],
};

export const getContractResponseSchema: JSONSchemaType<GetContractResponse> = {
    type: 'object',
    anyOf: [getContractSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};

export const isGetContractResponse = (input: any): input is GetContractSuccessResponse => {
    return (input as GetContractSuccessResponse).data !== undefined;
};
