export const appliancesTypes = {
    Diswasher: 'Diswasher',
    Hoover: 'Hoover',
    'American Style Fridge Freezer': 'American Style Fridge Freezer',
    Blender: 'Blender',
    'Coffee Machine': 'Coffee Machine',
    Cooker: 'Cooker',
    'Extractor Fan': 'Extractor Fan',
    Freezer: 'Freezer',
    Fridge: 'Fridge',
    'Fridge Freezer': 'Fridge Freezer',
    Gadget: 'Gadget',
    Hob: 'Hob',
    Microwave: 'Microwave',
    Oven: 'Oven',
    'Tumble Dryer': 'Tumble Dryer',
    TV: 'TV',
    'Washer Dryer': 'Washer Dryer',
    'Washing Machine': 'Washing Machine',
    'Wine Cooler': 'Wine Cooler',
    Multiappliances: 'Multiappliances',
    'Steam Iron': 'Steam Iron',
    'Tumble Dryer Electric': 'Tumble Dryer Electric',
    'Hob Electric': 'Hob Electric',
    'Oven Electric': 'Oven Electric',
    'Cooker Electric': 'Cooker Electric',
    'Tumble Dryer Gas': 'Tumble Dryer Gas',
    'Hob Gas': 'Hob Gas',
    'Oven Gas': 'Oven Gas',
    'Cooker Gas': 'Cooker Gas',
    'Cooker Dual': 'Cooker Dual',
    'Sat Nav': 'Sat Nav',
};

export const jobTypeMap = {
    boiler: 'Gas Job',
    heating: 'Gas Job',
    electric: 'Electric',
    plumbing: 'Plumbing',
    drainage: 'Drains',
    windows: 'Windows',
    locks: 'Locks',
    pests: 'Pests',
    doors: 'Doors',
    appliances: [
        'Diswasher',
        'Hoover',
        'American Style Fridge Freezer',
        'Blender',
        'Coffee Machine',
        'Cooker',
        'Extractor Fan',
        'Freezer',
        'Fridge',
        'Fridge Freezer',
        'Gadget',
        'Hob',
        'Microwave',
        'Oven',
        'Tumble Dryer',
        'TV',
        'Washer Dryer',
        'Washing Machine',
        'Wine Cooler',
        'Multiappliances',
        'Steam Iron',
        'Tumble Dryer Electric',
        'Hob Electric',
        'Oven Electric',
        'Cooker Electric',
        'Tumble Dryer Gas',
        'Hob Gas',
        'Oven Gas',
        'Cooker Gas',
        'Cooker Dual',
        'Sat Nav',
    ],
    ...appliancesTypes,
};
